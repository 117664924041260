<template lang='pug'>
  v-card
    .setup-table-wrapper
      TableWrapperCsvImport(
        :showSelect="true"
        :csv="employeesCsv"
        :columsOrder="columsOrder"
        :showDeleteButton="true"
        :tableLength="employeesTable.length"
        :isCanAddNewOption="true"
        :options="options"
        :onSaveEvent="onSaveEvent"
        :onSaveTableSteps="onSaveTableSteps"
        :table="cloneTableForFilter"
        @on-update-table="updateTable"
        @on-update-options="updateOptions"
        @on-close-order-modal="closeOrderModal"
        @on-add-employee="onAddEmployee"
        @on-add-csv="onFileUpload"
        @on-remove-many-rows="onRemoveManyRows"
        @is-error-mode="toggleErrorMode"
        @delete-row="onDeleteRow")
      v-btn(text slot='cancel' @click="prevStep") {{ 'ui.buttons.go_back'  | translate}}
      v-btn(slot="accept" @click="onSaveTable") {{ 'ui.buttons.next_step'  | translate}}
        i.fa.fa-arrow-right
</template>

<script>
import api from '@/api'
import uuidv1 from 'uuid/v1'
import { mapGetters, mapActions } from 'vuex'

import TableWrapperCsvImport from '@/components/ui/Table/TableWrapperCsvImport'

export default {
  name: 'SetupEmployees',

  components: {
    TableWrapperCsvImport
  },

  data: (vm) => ({
    employeesTable: [],
    cloneTableForFilter: [],
    options: {
      position: [],
      department: [],
      location: [],
      role: []
    },
    columsOrder: [
      { value: vm.$t('ui.labels.first_name'), key: 'firstName', type: 'text', id: uuidv1(), sorted: true, sortIncrease: false },
      { value: vm.$t('ui.labels.last_name'), key: 'lastName', type: 'text', id: uuidv1(), sorted: true, sortIncrease: false },
      { value: vm.$t('ui.labels.e_mail'), key: 'email', type: 'text', id: uuidv1(), sorted: true, sortIncrease: false },
      { value: vm.$t('ui.labels.position'), key: 'position', type: 'select', id: uuidv1(), sorted: true, sortIncrease: false },
      { value: vm.$t('ui.labels.department'), key: 'department', type: 'select', id: uuidv1(), sorted: true, sortIncrease: false },
      { value: vm.$t('ui.labels.location'), key: 'location', type: 'select', id: uuidv1(), sorted: true, sortIncrease: false },
      { value: vm.$t('ui.labels.role'), key: 'role', type: 'multiselect', id: uuidv1(), sorted: false, sortIncrease: false },
      { value: vm.$t('ui.labels.active'), key: 'active', type: 'switch', style: 'width:60px;', id: uuidv1(), sorted: true, sortIncrease: false }
    ],
    showModalAddEmployee: false,
    onSaveEventFunction: () => {}
  }),

  methods: {
    ...mapActions('setupWizard', ['setEmployeesCsv', 'setTableEmployee']),

    toggleErrorMode (param) {
      if (param) {
        this.cloneTableForFilter = this.employeesTable.filter(el => el.hasError)
      } else {
        this.cloneTableForFilter = [...this.employeesTable]
      }
    },

    onFileUpload (event) {
      this.setEmployeesCsv(event.target.files[0])
    },

    closeOrderModal () {
      this.setEmployeesCsv(null)
    },

    onRemoveManyRows () {
      this.employeesTable = this.employeesTable.filter(el => !el.selected)
      this.cloneTableForFilter = [...this.employeesTable]
    },

    onDeleteRow (id) {
      this.employeesTable.splice(this.employeesTable.findIndex(el => el.id === id), 1)
      this.cloneTableForFilter.splice(this.cloneTableForFilter.findIndex(el => el.id === id), 1)
    },

    updateTable (table) {
      let cloneTable = [...table]
      this.employeesTable.unshift(...cloneTable)
      this.cloneTableForFilter.unshift(...cloneTable)
    },

    onSaveEvent (func) {
      this.onSaveEventFunction = func
    },

    updateOptions (options) {
      this.options = options
    },

    onSaveTable () {
      this.onSaveEventFunction()
    },

    async onSaveTableSteps () {
      let requestTable = this.employeesTable.map(el => {
        return {
          employee: {
            firstName: el.firstName,
            lastName: el.lastName,
            email: el.email,
            active: el.active
          },
          role: el.role.map(item => { return { name: item ? item.name : '' } }),
          department: { name: el.department ? el.department.name : '' },
          location: { name: el.location ? el.location.name : '' },
          position: { name: el.position ? el.position.name : '' }
        }
      })

      const { data } = await api.importEmployees(requestTable)

      if (data.length && data.flat().filter(el => !!(el.errors || []).length).length) {
        data.forEach((el, index) => {
          if (el.length) {
            this.employeesTable[index].hasError = true
            this.employeesTable[index].errors = el
          } else {
            this.employeesTable[index].hasError = false
            this.employeesTable[index].errors = []
          }
        })
        this.employeesTable.forEach(el => { el.selected = false })
        this.cloneTableForFilter = [...this.employeesTable]
        return true
      } else {
        this.setTableEmployee({ options: this.options, table: this.employeesTable })
        this.nextStep()
        return false
      }
    },

    nextStep () {
      this.$router.push('/setup/summary')
    },

    prevStep () {
      this.$router.push('/setup/super-admin')
    },

    closeModalAddEmployee () {
      this.showModalAddEmployee = !this.showModalAddEmployee
    },

    onAddEmployee () {
      let newEmployee = {
        firstName: '',
        lastName: '',
        email: '',
        active: true,
        role: [],
        department: {},
        location: {},
        position: {},
        errors: [],
        hasError: false,
        selected: false,
        id: uuidv1()
      }
      this.employeesTable.unshift(newEmployee)
      this.cloneTableForFilter.unshift(newEmployee)
    }
  },

  computed: {
    ...mapGetters('setupWizard', ['employeesCsv']),
    ...mapGetters('app', ['roles'])
  }
}
</script>

<style lang="scss" scoped>
button i{
  font-size: 1rem;
  margin-left: 12px;
}
.setup-table-wrapper{
  padding: 20px 0px ;
}
</style>
